import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class G4ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="G4"
          description="View route information and buy your ticket for the G4 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">G4 service</h1>
              <p>Aberford Old Café - Garforth Academy (Via Micklefield)</p>
              <p>Garforth Academy – Church Lane/Hallfield Avenue</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="G4 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1CUSa7EiCTNWqZ9TUZ-4ykBF7yyu5WGw&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Garforth Academy
                </p>
                <p>
                  From Aberford via Main Street, Bunkers Hill, Aberford Road, Great North Road, Selby Road, Lidgett Lane, Garforth Academy.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="G4 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1WZvr9dG1Y9NgR7yJuHERbAu4PZXB6m4&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Garforth Academy
                </p>
                <p>
                  From Garforth Academy, Lidgett Lane, Selby Road, Great North Road, Church Lane.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Aberford Old Café</td>
                      <td>DEP</td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Great North Road/Hicks Memorial School</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Micklefield Station</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Garforth Academy</td>
                      <td>ARR</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Garforth Academy</td>
                      <td>DEP</td>
                      <td>1440</td>
                    </tr>
                    <tr>
                      <td>Micklefield Station</td>
                      <td>DEP</td>
                      <td>1455</td>
                    </tr>
                    <tr>
                      <td>Church Lane/Hallfield Avenue</td>
                      <td>ARR</td>
                      <td>1500</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFares />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default G4ServicePage
